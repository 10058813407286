import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./index.scss";
import { MsalProvider } from "@azure/msal-react";
import { getConfiguration } from "./authConfig";
import i18n from 'i18next';
import { jwtDecode } from "jwt-decode";
import { I18nextProvider } from 'react-i18next';
import { PublicClientApplication } from "@azure/msal-browser";

var idToken = window.location.search.split('id_token_hint=')[1];

if (idToken != undefined && idToken != null) {
  const decoded = jwtDecode(idToken);
  sessionStorage.setItem('idTokenHint', idToken)
  sessionStorage.setItem('loginType', 'idTokenHint');
  sessionStorage.setItem('companyId', decoded.companyId);
}

const pca = await PublicClientApplication.createPublicClientApplication(getConfiguration());
const container = document.getElementById("root");
const root = createRoot(container);

i18n.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('seletecdTranslationLang') ?? 'no' // Default language,
});

root.render(
  <React.StrictMode>
   <MsalProvider instance={pca}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App pca={pca}/>
      </I18nextProvider>
    </Provider>
   </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
