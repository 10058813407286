import { createSlice } from "@reduxjs/toolkit";
import {NotificationManager} from 'react-notifications';

const initialState = {
    companyEmailList: [],
    currentCompanyId: sessionStorage.getItem("companyId") ?? null,
    currentCompanyEmail: sessionStorage.getItem("companyEmail") ?? null,
    showNoCompanyEmail: false,
    currentCompanyName: sessionStorage.getItem("companyName") ?? null,
    companyEmailLoading: false
};

const companyEmailSlice = createSlice({
    name: "companyEmail",
    initialState,
    reducers: {
        setCompanyEmailListAction: (state, action) => {
            state.companyEmailList = action.payload;
        },
        setCompanyEmailAndIdAction: (state, action) => {
            state.currentCompanyEmail = action.payload.companyEmail;
            state.currentCompanyId = action.payload.companyId;
            state.currentCompanyName = action.payload.companyName;
            sessionStorage.setItem("companyId", action.payload.companyId);
            sessionStorage.setItem("companyEmail", action.payload.companyEmail);
            sessionStorage.setItem("companyName", action.payload.companyName);
        },
        setShowNoCompanyEmailAction: (state, action) => {
            state.showNoCompanyEmail = action.payload;
        },
        setCompanyEmailLoadingAction: (state, action) => {
            state.companyEmailLoading = action.payload;
        }
    }
});

export const {
    setCompanyEmailListAction,
    setCompanyEmailAndIdAction,
    setShowNoCompanyEmailAction,
    setCompanyEmailLoadingAction
} = companyEmailSlice.actions;
export default companyEmailSlice.reducer; 