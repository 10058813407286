import { GullLayouts } from "./index";
import { useSelector } from "react-redux";
import { useEffect, Suspense, Fragment } from "react";
import { Outlet, redirect, useLocation } from "react-router-dom";

const GullLayout = ({ children }) => {
  const { settings } = useSelector((state) => state.layout);
  const Layout = GullLayouts[settings.activeLayout];

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.setAttribute("dir", settings.dir);
    });
  }, []);

  return (
    <Suspense>
      <Fragment>
        <Layout>
          <Outlet />
        </Layout>
      </Fragment>
    </Suspense>
  );
};

export default GullLayout;
