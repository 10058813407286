import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    signature: ""
};

const signatureSlice = createSlice({
    name: "signature",
    initialState,
    reducers: {
        setSignatureDataAction: (state, action) => {
            state.signature = action.payload;
        },
    }
});

export const {
    setSignatureDataAction,
} = signatureSlice.actions;
export default signatureSlice.reducer;