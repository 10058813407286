import { getConfiguration } from "authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

const pca = await PublicClientApplication.createPublicClientApplication(getConfiguration());

async function getToken() {
    return pca.handleRedirectPromise()
    .then((response) => {
      const account = pca.getAllAccounts()[0];
      let accessTokenRequest = {
        scopes: ['openid', 'profile', process.env.REACT_APP_API_SCOPE],
        account: account,
      };
      return pca.acquireTokenSilent(accessTokenRequest)
    }).catch((err) => {
      console.log(err);
    })
}

async function getTokenRedirect() {
  return pca.handleRedirectPromise()
  .then((response) => {
    if (pca.getAllAccounts().length == 0) {
      const loginRedirectRequest = {
        extraQueryParameters: {
          "id_token_hint": sessionStorage.getItem('idTokenHint')
        }
      };
      return pca.loginRedirect(loginRedirectRequest);
    }
    else {
      let accessTokenRequest = {
        scopes: ['openid', 'profile', process.env.REACT_APP_API_SCOPE],
      };
      return pca.acquireTokenRedirect(accessTokenRequest);
    }
  }).catch((err) => {
    console.log(err);
  })
}

export { getToken, getTokenRedirect }