import { lazy } from "react";

const Inbox = lazy(() => import("./AppInbox"));
const InboxMailDetailPage = lazy(() => import("./InboxMailDetailPage"));
const inboxRoutes = [
  {
    path: "/",
    // exact: true,
    element: <Inbox />,
  },
  {
    path: "/detail",
    element: <InboxMailDetailPage />,
  }
];

export default inboxRoutes;
