import "../styles/app/app.scss";
import React, { useEffect } from "react";
import routes from "./routes";
import { RouterProvider } from "react-router-dom";
import { useLazyGetTranslationDataQuery } from "./redux/translation/translationApi";
import i18n from 'i18next';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLazyGetUserInfoQuery } from "./redux/userManager/userManagerAPI";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError} from "@azure/msal-browser";
import { getToken } from "app/auth/getToken";
import { Logout } from "app/auth/logout";

function App(props) {
  const { accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [getTranslationData] = useLazyGetTranslationDataQuery();
  const {selectedTranslationLang} = useSelector(state => state.translation);
  const [getuserInfo] = useLazyGetUserInfoQuery();

  props.pca.initialize().then(() => {
    // Redirect: once login is successful and redirects with tokens, call Graph API
    props.pca.handleRedirectPromise().then(handleResponse).catch(err => {
        sessionStorage.setItem('idTokenHint', '');
        console.error(err);
    });
  })
  
  function handleResponse(resp) {
    if (resp !== null) {
        let accountId = resp.account.homeAccountId;
        props.pca.setActiveAccount(resp.account);
        //showWelcomeMessage(resp.account);
    } else {
        // need to call getAccount here?
        const currentAccounts = props.pca.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
            return;
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
        } else if (currentAccounts.length === 1) {
            const activeAccount = currentAccounts[0];
            props.pca.setActiveAccount(activeAccount);
            let accountId = activeAccount.homeAccountId;
            // showWelcomeMessage(activeAccount);
        }
    }
  }
  
  useEffect(() => {
    var id_token_hint = sessionStorage.getItem('idTokenHint');
    if (inProgress !== InteractionStatus.None) return;

    async function authenticateUser() {
      const loginRedirectRequest = {
        scopes: ['openid', 'profile', process.env.REACT_APP_API_SCOPE],
        extraQueryParameters: {
          "id_token_hint": sessionStorage.getItem('idTokenHint')
        }
      };

      //console.log(sessionStorage.getItem('idTokenHint'));
      console.log("Logging in...");
      await props.pca.loginRedirect(loginRedirectRequest);
    }

    async function exchangeToken() {
      var token = await getToken();
      if (token === undefined) {
        await Logout();
      }
    }

    if (!isAuthenticated) {
      if (id_token_hint == null || id_token_hint === undefined || id_token_hint === "") {
        console.log("No id_token_hint found");
      } else {
        authenticateUser();
      }  
    }
    else
    {
      if (!inProgress) {
        exchangeToken();
      }
    }
    
    if (isAuthenticated) {
      if (localStorage.getItem('translation-en')) {
        i18n.addResourceBundle('en', 'translation', JSON.parse(localStorage.getItem('translation-en')), true, true);
      }

      if (localStorage.getItem('translation-no')) {
        i18n.addResourceBundle('no', 'translation', JSON.parse(localStorage.getItem('translation-no')), true, true);
      }

      getuserInfo();
      
      getTranslationData().then((result) => { 
        i18n.addResourceBundle('en', 'translation', JSON.parse(localStorage.getItem('translation-en')), true, true);
        i18n.addResourceBundle('no', 'translation', JSON.parse(localStorage.getItem('translation-no')), true, true);
      });
    }
  }, [isAuthenticated, inProgress])

  if (isAuthenticated) {
    return (
      <div>
        <RouterProvider router={routes} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  } else {
    var id_token_hint = sessionStorage.getItem('idTokenHint');
    if (id_token_hint == null || id_token_hint === undefined || id_token_hint === "") {
      return <div className="App">Invalid access</div>;
    }
    else {
      return <div className="App">Loggin in...</div>;
    } 
  }
}

export default App;
