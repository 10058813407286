import { configureStore } from "@reduxjs/toolkit";
import { apiSlice, apiSliceUserManager } from "./redux/api/apiSlice";
import layoutSliceReducer from "./redux/layout/layoutSlice";
import inboxReducer from "./redux/inbox/inboxSlice"
import translationReducer from "./redux/translation/translationSlice";
import companyEmailSliceReducer from "./redux/companyEmail/companyEmailSlice";
import signatureSlice from "./redux/signature/signatureSlice";
import userManagerReducer from "./redux/userManager/userManagerSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [apiSliceUserManager.reducerPath]: apiSliceUserManager.reducer,
    layout: layoutSliceReducer,
    inbox: inboxReducer,
    translation: translationReducer,
    companyEmail: companyEmailSliceReducer,
    signature: signatureSlice,
    userManager: userManagerReducer
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware, apiSliceUserManager.middleware),
});
