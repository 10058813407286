import { apiSliceUserManager } from "../api/apiSlice";
import { updateUserNameAction } from "./userManagerSlice";

export const userManagerAPI = apiSliceUserManager.injectEndpoints({
    endpoints: (builder) => ({
        getUserInfo: builder.query({
            query: () => "/user/getuser",
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                try {
                    const result = await queryFulfilled;
                    if (result.data) {
                        dispatch(updateUserNameAction(result.data.name));
                    }
                }
                catch(err) {
                    console.log(err)
                }
            }
        })
    })
});

export const {
    useLazyGetUserInfoQuery
  } = userManagerAPI;