import { apiSlice } from "../api/apiSlice";
import { setTranslationDataAction } from "./translationSlice";

export const translationAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTranslationData: builder.query({
            query: () => "/Translation/getTranslationData",
            async onQueryStarted(args, { queryFulfilled, dispatch }) {
                try {
                    const result = await queryFulfilled;
                    dispatch(setTranslationDataAction(result.data));
                }
                catch(err) {
                    console.log(err)
                }
            }
        })
     })
});

export const {
    useLazyGetTranslationDataQuery
  } = translationAPI;