import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, getTokenRedirect } from "app/auth/getToken";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    return getToken().then((response) => {
      let token = response.accessToken;
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("subscription-key", process.env.REACT_APP_SUBSCRIPTION_KEY);
      headers.set("companyEmail", sessionStorage.getItem("companyEmail"));
      headers.set("companyId", sessionStorage.getItem("companyId"));
      return headers;
    }).catch((error) => {
      console.log(error);
      // return getTokenRedirect().then((response) => {
      //   let token = response.accessToken
      //   headers.set("Authorization", `Bearer ${token}`);
      //   headers.set("subscription-key", process.env.REACT_APP_SUBSCRIPTION_KEY);
      //   headers.set("companyEmail", sessionStorage.getItem("companyEmail"));
      //   return headers;      
      // }).catch((err) => {
      //   console.log(err);
      // });
    });
  },
});

const baseQueryUserManager = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_USERMANAGER_BASE_URL,
  prepareHeaders: async (headers, {getState, endPoint }) => {
    return getToken().then((response) => {
      let token = response.accessToken;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    }).catch((error) => {
      console.log(error);
      // return getTokenRedirect().then((response) => {
      //   let token = response.accessToken
      //   headers.set("Authorization", `Bearer ${token}`);
      //   return headers;      
      // }).catch((err) => {
      //   console.log(err);
      // });
    })
  },
});

export const apiSliceUserManager = createApi({
  reducerPath: "userManagerAPI",
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQueryUserManager(args, api, extraOptions);

    if (result?.error?.status === 401) {
      //api.dispatch(userLoggedOut());
      //localStorage.clear();
    }
    return result;
  },
  tagTypes: [],
  endpoints: () => ({})
})

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
      //api.dispatch(userLoggedOut());
      //localStorage.clear();
    }
    return result;
  },
  tagTypes: [],
  endpoints: () => ({}),
});
