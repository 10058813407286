import { createSlice } from "@reduxjs/toolkit";
import { getFormattedTranslationData } from "@utils";

const initialState = {
    translationData: localStorage.getItem('translation-' + localStorage.getItem('seletecdTranslationLang') ?? 'no') ?? [],
    selectedTranslationLang: localStorage.getItem('seletecdTranslationLang') ?? 'no'
};

const translationSlice = createSlice({
    name: "translation",
    initialState,
    reducers: {
        setTranslationDataAction: (state, action) => {
            const englishData = getFormattedTranslationData(action.payload, 'en');
            const norwegianData = getFormattedTranslationData(action.payload, 'no');
            state.translationData = state.selectedTranslationLang === 'en' ? englishData : norwegianData;
            localStorage.setItem('translation-en', JSON.stringify(englishData));
            localStorage.setItem('translation-no', JSON.stringify(norwegianData));
        },
        changeLanguageAction: (state, action) => {
            state.selectedTranslationLang = action.payload;
            localStorage.setItem('seletecdTranslationLang', action.payload);
        }
    }
});

export const {
    setTranslationDataAction,
    changeLanguageAction
} = translationSlice.actions;
export default translationSlice.reducer;
