const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    userName: sessionStorage.getItem('userName')??''
};


const userManangerSlice = createSlice({
    name:'userManager',
    initialState,
    reducers: {
        updateUserNameAction: (state, action) => {
            state.userName = action.payload;
            sessionStorage.setItem('userName', action.payload);
        }
    }
});

export const {
    updateUserNameAction
} = userManangerSlice.actions;
export default userManangerSlice.reducer;
