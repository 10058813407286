import { PublicClientApplication } from "@azure/msal-browser";
import { getConfiguration } from "authConfig";

const pca = await PublicClientApplication.createPublicClientApplication(getConfiguration());

const Logout = async () => {
    const account = pca.getAllAccounts()[0];
    pca.logoutRedirect(account);
}

export {Logout};