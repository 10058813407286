import { createBrowserRouter, redirect } from "react-router-dom";
import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
import widgetsRoute from "./views/widgets/widgetsRoute";
import iconsRoutes from "./views/icons/iconsRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import GullLayout from "./GullLayout/GullLayout";

const routes = createBrowserRouter([
  {
    element: <GullLayout />,
    children: [
      ...inboxRoutes,
    ],
  },
]);

export default routes;
